import Cookies from 'universal-cookie';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import { of } from 'rxjs';

export const getIcono = ext => {
    let conf = [];
    let confIco = [];
    switch(ext) {
            case "pdf":
                    conf["icono"] = "far fa-file-pdf fa-8x";
                    conf["color"] = "#dd4b39";
                break;
            case "doc":
            case "docx":
                    conf["icono"] = "far fa-file-word fa-8x";
                    conf["color"] = "#3c8dbc";
                break;
            case "xls":
            case "xlsx":
                    conf["icono"] = "far fa-file-excel fa-8x";
                    conf["color"] = "#00a65a";
                break;
            default:
                    conf["icono"] = "fal fa-file fa-8x";
                    conf["color"] = "#3c8dbc";
                break;
      }

      return conf;
}

export const getIconoTable = ext => {
    let conf = [];
    let confIco = [];
    switch(ext.toLowerCase()) {
            case "pdf":
                    conf["icono"] = "far fa-file-pdf fa-2x";
                    conf["color"] = "#dd4b39";
                break;
            case "doc":
            case "docx":
                    conf["icono"] = "far fa-file-word fa-2x";
                    conf["color"] = "#3c8dbc";
                break;
            case "xls":
            case "xlsx":
                    conf["icono"] = "far fa-file-excel fa-2x";
                    conf["color"] = "#00a65a";
                break;
            default:
                    conf["icono"] = "fal fa-file fa-2x";
                    conf["color"] = "#3c8dbc";
                break;
      }

      return conf;
}

export const emailIsValid = email => {
    return /\S+@\S+\.\S+/.test(email)
}

export const setDeleteInputError = (valido, input) => {
    if( valido == 0 ){
        if( !input.classList.contains("is-valid") ){
            input.classList.add("is-valid");
            input.classList.remove("is-invalid");
        }
    }else if( valido == 1 ){
        if( !input.classList.contains("is-invalid") ){
            input.classList.add("is-invalid");
            input.classList.remove("is-valid");
        }
    }
}

export const deleteClass = clase => {
    let deleteClass = document.querySelectorAll(`.${clase}`);
    for( let i = 0; i < deleteClass.length; i++ ){
        deleteClass[i].classList.remove(clase);
    }        
}

export function getTreeJson(data, keysTrad, acumulado){

    if (isArray(data)) {
        recursiveArray(data, keysTrad, acumulado);
    } else if ((typeof data === 'object') && (data !== null)) {
        recursiveObject(data, keysTrad, acumulado);
    }
    
    return acumulado;
}

function recursiveArray(data,keysTrad, acumulado){

    for(let i=0; i<data.length; i++){
        if( typeof data[i] === "object" ){
            recursiveObject(data[i], keysTrad, acumulado);
        }
    }
}

function recursiveObject(data, keysTrad, acumulado) {

    for (var key in data) {
        if (data.hasOwnProperty(key)) {
               const existe = keysTrad.filter(keyTrad => keyTrad === key);               
                if(existe.length > 0){
                    if( !acumulado.includes(data[existe[0]]) ){    
                        if( typeof data[existe[0]] !== "object" ){                            
                            if( data[existe[0]] !== "" )
                            acumulado.push(data[existe[0]]);
                        }                    
                    }
                }
                getTreeJson(data[key], keysTrad, acumulado);
        }
    }
}


/*
export function translateTreeJson(data, traducciones){

    if (isArray(data)) {
        translateRecursiveArray(data, traducciones);
    } else if ((typeof data === 'object') && (data !== null)) {
        translateRecursiveObject(data, traducciones);
    }else{
        const tra = traducciones.filter( t => t["ori"] === data);
        if( tra.length > 0 ){
            data = tra[0]["tra"];
        }
    }
    
    return data;
}

function translateRecursiveArray(data,traducciones){

    for(let i=0; i<data.length; i++){
        if( typeof data[i] === "object" ){
            translateRecursiveObject(data[i], traducciones);
        }
    }
}

function translateRecursiveObject(data, traducciones) {

    for (var key in data) {
        if (data.hasOwnProperty(key)) {
               const res = translateTreeJson(data[key], traducciones);
               if( res != "" ){
                   data[key] = res;
               }
        }
    }
}

*/ 

export function translateTreeJson(data, traducciones, keysTrad){

    if (isArray(data)) {
        translateRecursiveArray(data, traducciones, keysTrad);
    } else if ((typeof data === 'object') && (data !== null)) {
        translateRecursiveObject(data, traducciones, keysTrad);
    }else{
        const tra = traducciones.filter( t => t["ori"] === data);
        if( tra.length > 0 ){
            data = tra[0]["tra"];
        }
    }
    
    return data;
}

function translateRecursiveArray(data,traducciones, keysTrad){

    for(let i=0; i<data.length; i++){
        if( typeof data[i] === "object" ){
            translateRecursiveObject(data[i], traducciones, keysTrad);
        }
    }
}

function translateRecursiveObject(data, traducciones, keysTrad) {

    for (var key in data) {
        if (data.hasOwnProperty(key)) {
            const res = translateTreeJson(data[key], traducciones, keysTrad);
            if( res != "" ){
                   const existe = keysTrad.filter(keyTrad => keyTrad === key);               
                   if(existe.length > 0){
                       data[key] = res;                        
                   }
               }
        }
    }
}

export function isArray(o) {
    return Object.prototype.toString.call(o) === '[object Array]';
}

/***** FECHAS Y HORAS *****/
export const dateSqlToLocal = (fecha, local="es-ES") => {

    if( fecha !== "" && fecha != "0000-00-00T00:00" ){
        const CurrentDate = fecha+":00";
        let data= new Date(CurrentDate)
        let month = data.getMonth() + 1
        let day = data.getDate()
        let year = data.getFullYear()
        if(day<=9)
          day = '0' + day
        if(month<10)
          month = '0' + month
        const nuevafecha = day + '/' + month + '/' + year
        return nuevafecha;
    }
    else{
        const nuevafecha = "";
        return nuevafecha
    }
}

export const getFechaHoraActual = () => {
    let f = new Date();
    let mes = f.getMonth()+1 < 10 ? "0"+(f.getMonth()+1) : f.getMonth()+1;
    let dia = f.getDate() < 10 ? "0"+f.getDate() : f.getDate();
    let hora = f.getHours() < 10 ? "0"+f.getHours() : f.getHours();
    let minutos = f.getMinutes() < 10 ? "0"+f.getMinutes() : f.getMinutes();
    let segundos = f.getSeconds() < 10 ? "0"+f.getSeconds() : f.getSeconds();
        
    let ahora = hora+":"+minutos+":"+segundos+" "+dia+"/"+mes+"/"+f.getFullYear();

    return ahora;
}

export const getFechaHoraActualSql = () => {
    let f = new Date();
    let mes = f.getMonth()+1 < 10 ? "0"+(f.getMonth()+1) : f.getMonth()+1;
    let dia = f.getDate() < 10 ? "0"+f.getDate() : f.getDate();
    let hora = f.getHours() < 10 ? "0"+f.getHours() : f.getHours();
    let minutos = f.getMinutes() < 10 ? "0"+f.getMinutes() : f.getMinutes();
    let segundos = f.getSeconds() < 10 ? "0"+f.getSeconds() : f.getSeconds();
        
    let ahora = f.getFullYear()+"-"+mes+"-"+dia+"T"+hora+":"+minutos+":"+segundos;

    return ahora;
}

export const getFechaHoraActualState = () =>{
    let f = new Date();
    let mes = f.getMonth()+1 < 10 ? "0"+(f.getMonth()+1) : f.getMonth()+1;
    let dia = f.getDate() < 10 ? "0"+f.getDate() : f.getDate();
    let hora = f.getHours() < 10 ? "0"+f.getHours() : f.getHours();
    let minutos = f.getMinutes() < 10 ? "0"+f.getMinutes() : f.getMinutes();
    let segundos = f.getSeconds() < 10 ? "0"+f.getSeconds() : f.getSeconds();
        
    let ahora = dia+"/"+mes+"/"+f.getFullYear()+" "+hora+":"+minutos+":"+segundos;

    return ahora;
}

export const getAhoraddmmyyyy = () => {
    let f = new Date();
    let mes = f.getMonth()+1 < 10 ? "0"+(f.getMonth()+1) : f.getMonth()+1;
    let dia = f.getDate() < 10 ? "0"+f.getDate() : f.getDate();

    let ahora = dia+"/"+mes+"/"+f.getFullYear();

    return ahora;
}

export const getAhorayyyymmdd = () => {
    let f = new Date();
    let mes = f.getMonth()+1 < 10 ? "0"+(f.getMonth()+1) : f.getMonth()+1;
    let dia = f.getDate() < 10 ? "0"+f.getDate() : f.getDate();

    let ahora = f.getFullYear()+"-"+mes+"-"+dia;

    return ahora;
}

export const diffDatesToString = (fecha1, fecha2, traduction, soloFecha, returnMensaje) => {

    let alcance = 0;
    let f = new Date();
    let mes = f.getMonth()+1 < 10 ? "0"+(f.getMonth()+1) : f.getMonth()+1;
    let dia = f.getDate() < 10 ? "0"+f.getDate() : f.getDate();
    let anho = f.getFullYear();
    fecha2 = anho+"-"+mes+"-"+dia+"T00:00";
  
    if( fecha1 !== "" && fecha2 !== "" && fecha1 !== undefined && fecha2 !== undefined && fecha1 != "0000-00-00T00:00" && fecha2 != "0000-00-00T00:00" && fecha1 != null && fecha2 != null ){
        if( soloFecha ){
            fecha1 = fecha1.split("T")[0]+"T00:00";
            // fecha2 = fecha2.split("T")[0]+"T00:00";
            // fecha2 = anho+"-"+mes+"-"+dia+"T00:00";
            alcance = 3;
        }else{
            alcance = 6;
        }
        const fec1 = new Date(fecha1);
        const fec2 = new Date(fecha2);

        const message = getNiceTime(fec1, fec2, alcance, true, traduction, returnMensaje)     
        return message;        
    }else{
        return "";
    }

}


function getNiceTime(fromDate, toDate, levels, prefix, traduction, returnMensaje){
    var lang = {
            "date.past": `${traduction.past.title} {0}`,
            "date.future": `${traduction.future.title} {0}`,
            "date.now": `${traduction.now.title}`,
            "date.year": `{0} ${traduction.year.title}`,
            "date.years": `{0} ${traduction.years.title}`,
            "date.years.prefixed": `{0} ${traduction.years.title}`,
            "date.month": `{0} ${traduction.month.title}`,
            "date.months": `{0} ${traduction.months.title}`,
            "date.months.prefixed": `{0} ${traduction.months.title}`,
            "date.day": `{0} ${traduction.day.title}`,
            "date.days": `{0} ${traduction.days}`,
            "date.days.prefixed": `{0} ${traduction.days.title}`,
            "date.hour": `{0} ${traduction.hour.title}`,
            "date.hours": `{0} ${traduction.hours.title}`,
            "date.hours.prefixed": `{0} ${traduction.hours.title}`,
            "date.minute": `{0} ${traduction.minute.title}`,
            "date.minutes": `{0} ${traduction.minutes.title}`,
            "date.minutes.prefixed": `{0} ${traduction.minutes.title}`,
            "date.second": `{0} ${traduction.second.title}`,
            "date.seconds": `{0} ${traduction.seconds.title}`,
            "date.seconds.prefixed": `{0} ${traduction.seconds.title}`
        },
        langFn = function(id,params){
            var returnValue = lang[id] || "";
            if(params){
                for(var i=0;i<params.length;i++){
                    returnValue = returnValue.replace("{"+i+"}",params[i]);
                }
            }
            return returnValue;
        },
        toDate = toDate ? toDate : new Date(),
        diff = fromDate - toDate,
        past = diff < 0 ? true : false,
        diff = diff < 0 ? diff * -1 : diff,
        date = new Date(new Date(1970,0,1,0).getTime()+diff),
        returnString = '',
        count = 0,
        years = (date.getFullYear() - 1970);            

    if(years > 0){
        var langSingle = "date.year" + (prefix ? "" : ""),
            langMultiple = "date.years" + (prefix ? ".prefixed" : "");
        returnString += (count > 0 ?  ', ' : '') + (years !== 1 ? langFn(langMultiple,[years]) : langFn(langSingle,[years]));
        count ++;
    }

    var months = date.getMonth();
    if(count < levels ){
        var langSingle = "date.month" + (prefix ? "" : ""),
            langMultiple = "date.months" + (prefix ? ".prefixed" : "");
        returnString += (count > 0 ?  ', ' : '') + (months !== 1 ? langFn(langMultiple,[months]) : langFn(langSingle,[months]));
        count ++;
    } else {
        if(count > 0)
            count = 99;
    }

    var days = date.getDate() - 1;
    if(count < levels ){
        var langSingle = "date.day" + (prefix ? "" : ""),
            langMultiple = "date.days" + (prefix ? ".prefixed" : "");
        returnString += (count > 0 ?  ', ' : '') + (days !== 1 ? langFn(langMultiple,[days]) : langFn(langSingle,[days]));
        count ++;
    } else {
        if(count > 0)
            count = 99;
    }

    var hours = date.getHours();    
    if(count < levels ){
        var langSingle = "date.hour" + (prefix ? "" : ""),
            langMultiple = "date.hours" + (prefix ? ".prefixed" : "");
        returnString += (count > 0 ?  ', ' : '') + (hours !== 1 ? langFn(langMultiple,[hours]) : langFn(langSingle,[hours]));
        count ++;
    } else {
        if(count > 0)
            count = 99;
    }

    var minutes = date.getMinutes();
    if(count < levels ){
        var langSingle = "date.minute" + (prefix ? "" : ""),
            langMultiple = "date.minutes" + (prefix ? ".prefixed" : "");
        returnString += (count > 0 ?  ', ' : '') + (minutes !== 1 ? langFn(langMultiple,[minutes]) : langFn(langSingle,[minutes]));
        count ++;
    } else {
        if(count > 0)
            count = 99;
    }

    var seconds = date.getSeconds();
    if(count < levels ){
        var langSingle = "date.second" + (prefix ? "" : ""),
            langMultiple = "date.seconds" + (prefix ? ".prefixed" : "");
        returnString += (count > 0 ?  ', ' : '') + (seconds !== 1 ? langFn(langMultiple,[seconds]) : langFn(langSingle,[seconds]));
        count ++;
    } else {
        if(count > 0)
            count = 99;
    }

    if(prefix){
        if(returnString == "" || ( years === 0 && months === 0 && days === 0 && hours === 0 && minutes === 0 ) ){
            returnString = langFn("date.now");
            if( !returnMensaje ){
                return "now";
            }
        } else if(past){
            returnString = langFn("date.past",[returnString]);
            if( !returnMensaje ){
                return "past";
            }
        }else{
            returnString = langFn("date.future",[returnString]);
            if( !returnMensaje ){
                return "future";
            }
        }
    }
    return returnString;
}








export const filtrosToWhere = (data) => {

    let where = "";
    let condiciones = [];

    for (const [key, value] of Object.entries(data)) {        
        let condicion = "";
        if( key != "plantilla" && key !=  "destinatarios" ){
            if( !key.includes("filtro") ){
                let aux = key.split("-");
                let filtro = data[`${aux[0]}-filtro-${aux[1]}`];
                
                condicion = ` ${aux[1]} ${filtro} '${value}'`;
                
                if( condicion.includes('igual') || condicion.includes('distinto') || condicion.includes('contiene') || condicion.includes('nocontiene') ||condicion.includes('todos') ){
                    condicion = translateCondicion(condicion, filtro, value);
                }

                if( condicion != "" ){
                    condiciones.push(condicion);
                } 
            }
        }   
    }

    for( let i = 0; i < condiciones.length; i++ )
    {
        if(i == 0){
            where += ` ${condiciones[i]} `;
        }else{
            where += ` AND ${condiciones[i]} `;
        }
    }

    return where;
}

function translateCondicion(condicion, filtro, value){

    if( filtro == "igual" ){
        condicion = condicion.replace("igual" , "=");
    }
    if( filtro == "distinto" ){
        condicion = condicion.replace("distinto" , "<>");
    }
    if( filtro == "contiene" ){
        let aux = condicion.split("contiene");
        condicion = `${aux[0]} LIKE '%${value}%' `;
    }
    if( filtro == "nocontiene" ){
        let aux = condicion.split("nocontiene");
        condicion = `${aux[0]} NOT LIKE '%${value}%' `;
    }
    if( filtro == "todos" ){
        condicion = "";
    }

    return condicion;

}


export const txtFalsoSql = (sql, variables) => {

    let where = "";
    let condiciones = [];
    if( variables.length > 0){
        let vars = JSON.parse(variables);      
        vars.map((variable, index, arr) => {
            let condicion = "";
            let value = "XXXX";
            let filtro = "";
            if( variable.condef != "" ){
                filtro = variable.condef;
            }else{
                filtro = "CONDICION";
            }
            condicion = ` ${variable.idcon} ${filtro} '${value}'`;
            if( condicion.includes('igual') || condicion.includes('distinto') || condicion.includes('contiene') || condicion.includes('nocontiene') ||condicion.includes('todos') ){
                condicion = translateCondicion(condicion, filtro, value);
            }
            
            if( condicion != "" ){
                condiciones.push(condicion);
            } 
        })

        for( let i = 0; i < condiciones.length; i++ )
        {
            if(i == 0){
                where += ` ${condiciones[i]} `;
            }else{
                where += ` AND ${condiciones[i]} `;
            }
        }

    } 

    if( sql.includes("******FILTRO******") ){
        // sql = sql.replace("******FILTRO******", where);
        sql = MiReplaceAll(sql, "******FILTRO******", where);
    }

    return sql;
}

export const MiReplaceAll = (texto, search, replaceWith) => {
   return texto = texto.split(search).join(replaceWith);
}

export const createTxtFiltrosSearch = (vars) => {

    const condiciones = [];
    
    if( vars ){
        const elementos = vars.children;

        for(let i = 0; i < elementos.length; i++){
            let label = "";
            let filtro = "";
            let valor = "";
            let condicion;
            if( elementos[i].classList.contains('filterVar') ){
                label = elementos[i].children[0].innerText;
                filtro = elementos[i].children[1].innerText;
                if( filtro != "Todos" ){
                    let inputEstaCondicion = elementos[i].nextSibling;
                    if( inputEstaCondicion ){
                        if( inputEstaCondicion.children[1].nodeName == "DIV" ){
                            valor = inputEstaCondicion.children[1].textContent;
    
                        }else if( inputEstaCondicion.children[1].nodeName == "INPUT" ){
                            if( inputEstaCondicion.children[1].type == "datetime-local" ){
                                valor =  dateSqlToLocal(inputEstaCondicion.children[1].value);
                            }else{
                                valor =  inputEstaCondicion.children[1].value;
                            }
                        }
    
                    }
                }
                condiciones.push({ cond : `${label} ${filtro} ${valor}` });
            }
        }

    }

    
    return condiciones;
}

export const getIndexUrl = (menuMode, origen=null) => {
    
    const cookies = new Cookies();

    let url = "/inicio";

    switch(menuMode) {
            case "checklist":
                    if( cookies.get('nivel') == 4  ){
                        url = "/checklisttrab";    
                    }else{
                        url = "/checklist";
                    }
                break;
            default:
                    if( origen == "wizardchecklist" ){
                        if( cookies.get('nivel') == 4  ){
                            url = "/checklisttrab";    
                        }else{
                            url = "/checklist";
                        }
                    }else{
                        url = "/inicio";
                    }

                break;
      }

      return url;
}


export const bootStrapAlertClassToColor = classBST => {
    
    const cookies = new Cookies();

    let color = "";

    switch(classBST) {
            case "alert-secondary":
                   color = "#888888";
                break;
            case "alert-success":
                   color = "#28a745";
                 break;            
            case "alert-warning":
                   color = "#ffc107";
                 break;
            case "alert-danger":
                   color = "#dc3545";
                break;
            case "alert-primary":
                   color = "#007bff";
                 break;
      }

      return color;
}


export const getApiUrl = (project) => {
    
    let api = "";

    switch(project) {
            // Localhost
            case "http://localhost:3000":
            case "https://localhost:3000":
            case "http://localhost:3001":
            case "https://localhost:3001":
            case "http://localhost:3002":
            case "https://localhost:3002":
            case "http://iishost.perizia.es:3000":               
                        api = "https://api.dev.aitodetec.com";
                break;
            // Upkeep
            case "http://login.upkeep.periziaonline.es":
            case "https://login.upkeep.periziaonline.es":
                    api = "https://api.upkeep.periziaonline.es";
                break;
            // Polarbay
            case "http://home.polarbay.argos-coterena.com":
            case "https://home.polarbay.argos-coterena.com":
                    api = "https://api.polarbay.argos-coterena.com";
                break;
            // Polarseafish
            case "http://home.polarseafish.argos-coterena.com":
            case "https://home.polarseafish.argos-coterena.com":
                    api = "https://api.polarseafish.argos-coterena.com";
                break;
            // Demo
            case "http://home.demo.argos-coterena.com":
            case "https://home.demo.argos-coterena.com":                
                    api = "https://api.demo.argos-coterena.com";
                break;
            // MAINTENANCE LOCAL PRUEBAS ISSHOST 2
            case "http://home.argos.local":
            case "https://home.argos.local":
                    api = "http://api.argos.local";
                break;
            // ***** COTERENA ***** 
            case "http://home.activos.argos-coterena.com":
            case "https://home.activos.argos-coterena.com":
                api = "https://api.activos.argos-coterena.com";
                break;
            // ***** El front de alarmas se gestiona a través del api de activos *****             
            case "http://home.alarm.argos.aitodetec.com":
            case "https://home.alarm.argos.aitodetec.com":
                api = "https://api.activos.argos-coterena.com";
                break;
            default:
                api = project.replace("//home.", "//api.");
                break;
                
      }

      return api;
}

export const stringToHtml = (str) => {
    return {__html: str};
}

export const getLinkReadOnly = (optionCfg) => {
    if( optionCfg == "1" ){
        return "";
    }else{
        return "/readonly";
    }
}


export const axiosPost = async(url, data, cancelToken) => {
    
    let respuesta;

    if( cancelToken ){
        respuesta = await axios.post(url, data, {cancelToken: cancelToken});
    }else{
        respuesta = await axios.post(url, data);
    }

    return respuesta;
}


export const getPantDocFromNivel = (nivel) => {
    
    let pantalla = "";

    switch(nivel) {
            case "1":
            case "2":
                    // Administradores y SuperAdministradores
                    pantalla = "/documentos";
                break;
            case "3":
                    // Encargado
                    pantalla = "/documentosenc";
            break;           
      }

      return pantalla;
}

export const getEscalaTemporal = (minutos) => {
    let data = "";
    let meses = "";
    let semanas = "";
    let dias = "";
    let horas = "";
    let min = "";

    // Meses. 1 Mes = 43200 minutos
    if( minutos >= 43200 ){
        data = Math.floor(minutos/43200) == 1 ? `${Math.floor(minutos/43200)} mes` : Math.floor(minutos/43200) > 1 ? `${Math.floor(minutos/43200)} meses` : "";
        minutos = minutos%43200;
    }
    // Semanas 1 Semana = 10080 minutos
    if( minutos >= 10080 ){
        semanas = Math.floor(minutos/10080) == 1 ? `${Math.floor(minutos/10080)} semana` : Math.floor(minutos/10080) > 1 ? `${Math.floor(minutos/10080)} semanas` : "";
        minutos = minutos%10080;
        if( data != "" ){
            data += `, ${semanas}`;
        }else{
            data = semanas;
        }
    }
    // Días 1 Día = 1440 minutos
    if( minutos >= 1440 ){
        dias = Math.floor(minutos/1440) == 1 ? `${Math.floor(minutos/1440)} día` : Math.floor(minutos/1440) > 1 ? `${Math.floor(minutos/1440)} días` : "";
        minutos = minutos%1440;
        if( data != "" ){
            data += `, ${dias}`;
        }else{
            data = dias;
        }
    }
    // Horas 1 Hora = 60 minutos
    if( minutos >= 60 ){
        horas = Math.floor(minutos/60) == 1 ? `${Math.floor(minutos/60)} hora` : Math.floor(minutos/60) > 1 ? `${Math.floor(minutos/60)} horas` : "";
        minutos = minutos%60;
        if( data != "" ){
            data += `, ${horas}`;
        }else{
            data = horas;
        }
    }
    // Minutos
    min = minutos == 1 ? `${minutos} minuto` : minutos > 1 ?  `${minutos} minutos` : "" ;
    if( data != "" ){
        if( min != "" ){
            data += `, ${min}`;
        }else{
            data += min;    
        }
    }else{
        data = min;
    }


    return data;
}

export const replaceTextosAlarmas = (texto, centroVar, equipoVar, variableVar, condicionVar, intervaloVar, nivelVar, destinatariosVar, refsupinfVar, rangoMinVar, rangoMaxVar) => {

    texto = centroVar        != "" ? MiReplaceAll(texto, "%CEN%", centroVar) : texto;
    texto = equipoVar        != "" ? MiReplaceAll(texto, "%EQU%", equipoVar) : texto;
    texto = variableVar      != "" ? MiReplaceAll(texto, "%VAR%", variableVar) : texto;
    texto = condicionVar     != "" ? MiReplaceAll(texto, "%CON%", condicionVar) : texto;
    texto = intervaloVar     != "" ? MiReplaceAll(texto, "%EST%", getEscalaTemporal(intervaloVar)) : texto;
    texto = intervaloVar     != "" ? MiReplaceAll(texto, "%TIM%", intervaloVar) : texto;
    texto = nivelVar         != "" ? MiReplaceAll(texto, "%LVL%", nivelVar) : texto;
    texto = destinatariosVar != "" ? MiReplaceAll(texto, "%DES%", destinatariosVar) : texto;
    texto = refsupinfVar     != "" ? MiReplaceAll(texto, "%REF%", refsupinfVar) : texto;
    texto = rangoMinVar      != "" ? MiReplaceAll(texto, "%MIN%", rangoMinVar) : texto;
    texto = rangoMaxVar      != "" ? MiReplaceAll(texto, "%MAX%", rangoMaxVar) : texto;

    return texto;

}



export const decryptData = async (encryptedData, cryptMode, forceDecrypt=false) => {
   
    if(cryptMode == "1" || forceDecrypt == true){
        // Parsear los datos recibidos
        const password = getPassWord();
        const decodedData = JSON.parse(atob(encryptedData));
        const ciphertext = base64ToArrayBuffer(decodedData.ciphertext);
        const iv = base64ToArrayBuffer(decodedData.iv);
        const salt = base64ToArrayBuffer(decodedData.salt);
        const tag = base64ToArrayBuffer(decodedData.tag);
        const iterations = decodedData.iterations;
    
        // Derivar clave usando PBKDF2
        const encoder = new TextEncoder();
        const keyMaterial = await window.crypto.subtle.importKey(
            'raw',
            encoder.encode(password),
            { name: 'PBKDF2' },
            false,
            ['deriveKey']
        );
    
        const key = await window.crypto.subtle.deriveKey(
            {
                name: 'PBKDF2',
                salt: salt,
                iterations: iterations,
                hash: 'SHA-256'
            },
            keyMaterial,
            { name: 'AES-GCM', length: 256 },
            false,
            ['decrypt']
        );
    
        // Desencriptar
        const decrypted = await window.crypto.subtle.decrypt(
            {
                name: 'AES-GCM',
                iv: iv,
                additionalData: new Uint8Array([]),
                tagLength: 128
            },
            key,
            concatenateBuffers(ciphertext, tag)
        );
    
        const decoder = new TextDecoder();
        return decoder.decode(decrypted);
    }else{
        return encryptedData;
    }

}

export const encryptData = async (data, cryptMode) => {

    if(cryptMode == "1"){
        const password = getPassWord();
    
        // Generar sal para PBKDF2
        const salt = window.crypto.getRandomValues(new Uint8Array(16));
        
        // Generar IV para AES-GCM
        const iv = window.crypto.getRandomValues(new Uint8Array(12));
    
        // Derivar clave usando PBKDF2
        const encoder = new TextEncoder();
        const keyMaterial = await window.crypto.subtle.importKey(
            'raw',
            encoder.encode(password),
            { name: 'PBKDF2' },
            false,
            ['deriveKey']
        );
        const iterations = 100000;
        const key = await window.crypto.subtle.deriveKey(
            {
                name: 'PBKDF2',
                salt: salt,
                iterations: iterations,
                hash: 'SHA-256'
            },
            keyMaterial,
            { name: 'AES-GCM', length: 256 },
            false,
            ['encrypt']
        );
    
        // Encriptar los datos
        const encodedData = encoder.encode(data);
        const encrypted = await window.crypto.subtle.encrypt(
            {
                name: 'AES-GCM',
                iv: iv,
                additionalData: new Uint8Array([]),
                tagLength: 128
            },
            key,
            encodedData
        );
    
        // Separar el tag del ciphertext
        const ciphertext = encrypted.slice(0, -16);
        const tag = encrypted.slice(-16);
    
        // Codificar en base64 para transmitir
        const base64Ciphertext = arrayBufferToBase64(ciphertext);
        const base64Iv = arrayBufferToBase64(iv);
        const base64Salt = arrayBufferToBase64(salt);
        const base64Tag = arrayBufferToBase64(tag);
    
        return btoa(JSON.stringify({
            ciphertext: base64Ciphertext,
            iv: base64Iv,
            salt: base64Salt,
            tag: base64Tag,
            iterations: iterations
        }));
    }else{
        return data;
    }    
}

const getPassWord = () => {
    // Obtener el referer de la solicitud
    var referer = window.location.hostname;
    
    // Eliminar el protocolo (http, https, etc.) del referer
    referer = referer.replace(/^(http:\/\/|https:\/\/)/, '');
    
    // Convertir a minúsculas y eliminar caracteres que no sean letras de la a a la z
    var refererFiltrado = referer.toLowerCase().replace(/[^a-z]/g, '');
    
    // Contar vocales y consonantes
    var vocales = (refererFiltrado.match(/[aeiou]/g) || []).length;
    var consonantes = (refererFiltrado.match(/[bcdfghjklmnpqrstvwxyz]/g) || []).length;
    
    // Añadir números de vocales y consonantes al final del string modificado
    var resultadoFinal = refererFiltrado + vocales + consonantes;
    
    return resultadoFinal;
}

const ensureLength = (input, length) => { 
    const caracteresAleatorios = 'abcdefghijklmnopqrstuvwxyz0123456789'; 
    let resultado = input; 
    while (resultado.length < length) { 
        const randomIndex = Math.floor(Math.random() * caracteresAleatorios.length); 
        resultado += caracteresAleatorios.charAt(randomIndex); 
    } 
        return resultado; 
}

const secretKey = ensureLength(getPassWord(), 32); // Asegurar que la clave tiene 32 caracteres 
const iv = ensureLength(getPassWord(), 16); // Asegurar que el IV tiene 16 caracteres 

const encrypt = (data) => { 
    const cipher = CryptoJS.AES.encrypt(data, CryptoJS.enc.Utf8.parse(secretKey), { iv: CryptoJS.enc.Utf8.parse(iv) });    
    return cipher.ciphertext.toString(CryptoJS.enc.Hex);
} 
const decrypt = (ciphertext) => {     
    const encryptedHexStr = CryptoJS.enc.Hex.parse(ciphertext);
    const bytes = CryptoJS.AES.decrypt({
        ciphertext: encryptedHexStr,
        salt: '', 
        iv: CryptoJS.enc.Utf8.parse(iv), 
        key: CryptoJS.enc.Utf8.parse(secretKey)
    }, CryptoJS.enc.Utf8.parse(secretKey), { iv: CryptoJS.enc.Utf8.parse(iv) }); 
    return bytes.toString(CryptoJS.enc.Utf8);
}

export const setEncodedCookie = (key, value, options) => { 
    const cookies = new Cookies();
    
    // const encodedKey = encrypt(key); 
    // const encodedValue = encrypt(value);    
    // cookies.set(encodedKey, encodedValue, options);
    cookies.set(key, value, options); 
}

export const getDecodedCookie = (key) => {
    const cookies = new Cookies();    
    
    // const encodedKey = encrypt(key); 
    // const encodedValue = cookies.get(encodedKey);       
    // return encodedValue ? decrypt(encodedValue) : null;
    return cookies.get(key); 
}

export const removeEncodedCookie = (key, options) => {
    const cookies = new Cookies();

    // const encodedKey = encrypt(key); 
    // cookies.remove(encodedKey, options);
    cookies.remove(key, options); 
}

export const getCookieOptions = () => {
    // Fecha actual
    const now = new Date();
    
    // Calcular fecha de expiración (1 mes a partir de ahora)
    const expirationDate = new Date(now.getTime() + 30 * 24 * 60 * 60 * 1000);

    // Configuración común para todas las cookies
    const cookieOptions = {
        path: '/',
        expires: expirationDate,
        secure: true,
        // domain: window.location.hostname,
        sameSite: 'lax'        
    };
    
    return cookieOptions;
}

export const helperSetCookiesLogin = (usuario) => {
    
    const cookies = new Cookies();

    cookies.set('id', usuario.id, {path: '/'});
    cookies.set('email', usuario.email, {path: '/'});
    cookies.set('nombre', usuario.nombre, {path: '/'});
    cookies.set('sesion', usuario.sesion, {path: '/'});  
    cookies.set('nivel', usuario.nivel, {path: '/'});  
    cookies.set('estado', usuario.estado, {path: '/'});
    cookies.set('idioma', usuario.idioma.toLowerCase(), {path: '/'});
    cookies.set('imguser', usuario.imguser, {path: '/'});  
    cookies.set('idage', usuario.idage, {path: '/'})

  
}

export const helperDeleteCookiesLogin = () => {
    
    const cookies = new Cookies();
    
    cookies.remove('id', {path: '/'});
    cookies.remove('email', {path: '/'});
    cookies.remove('nombre', {path: '/'});
    cookies.remove('sesion', {path: '/'});
    cookies.remove('nivel', {path: '/'});
    cookies.remove('estado', {path: '/'});
    cookies.remove('idioma', {path: '/'});
    cookies.remove('imguser', {path: '/'});
    cookies.remove('idage', {path: '/'});
    cookies.remove('intervalo', {path: '/'});
    cookies.remove('kpiIni', {path: '/'});
    cookies.remove('kpiFin', {path: '/'});
    cookies.remove('defaultActiveKey', {path: '/'});
    cookies.remove('defaultActiveKeyCheckList', {path: '/'});
  
}


// export const helperSetCookiesLogin = (usuario) => {        
//     setEncodedCookie('id', usuario.id, getCookieOptions());
//     setEncodedCookie('email', usuario.email, getCookieOptions());
//     setEncodedCookie('nombre', usuario.nombre, getCookieOptions());
//     setEncodedCookie('sesion', usuario.sesion, getCookieOptions());  
//     setEncodedCookie('nivel', usuario.nivel, getCookieOptions());  
//     setEncodedCookie('estado', usuario.estado, getCookieOptions());
//     setEncodedCookie('idioma', usuario.idioma.toLowerCase(), getCookieOptions());
//     setEncodedCookie('imguser', usuario.imguser, getCookieOptions());     
//     setEncodedCookie('idage', usuario.idage, getCookieOptions());   
// }

// export const helperDeleteCookiesLogin = () => {       
    
//     removeEncodedCookie('id', getCookieOptions());
//     removeEncodedCookie('email', getCookieOptions());
//     removeEncodedCookie('nombre', getCookieOptions());
//     removeEncodedCookie('sesion', getCookieOptions());
//     removeEncodedCookie('nivel', getCookieOptions());
//     removeEncodedCookie('estado', getCookieOptions());
//     removeEncodedCookie('idioma', getCookieOptions());
//     removeEncodedCookie('imguser', getCookieOptions());
//     removeEncodedCookie('idage', getCookieOptions());
//     removeEncodedCookie('intervalo', getCookieOptions());
//     removeEncodedCookie('kpiIni', getCookieOptions());
//     removeEncodedCookie('kpiFin', getCookieOptions());
//     removeEncodedCookie('defaultActiveKey', getCookieOptions());
//     removeEncodedCookie('defaultActiveKeyCheckList', getCookieOptions());
//     removeEncodedCookie('filterRegs', getCookieOptions());
  
// }

const base64ToArrayBuffer = (base64) => {
    const binaryString = atob(base64);
    const length = binaryString.length;
    const bytes = new Uint8Array(length);
    for (let i = 0; i < length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
}

const concatenateBuffers = (buffer1, buffer2) => {
    const tmp = new Uint8Array(buffer1.byteLength + buffer2.byteLength);
    tmp.set(new Uint8Array(buffer1), 0);
    tmp.set(new Uint8Array(buffer2), buffer1.byteLength);
    return tmp.buffer;
}

const arrayBufferToBase64 = (buffer) => {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
}